import { Input } from '@chakra-ui/react';
import { TwDatePicker } from 'components/DatePicker';
import {
  CurrencyNumberInput,
  PercentageNumberInput,
  TwNumberInput,
} from 'components/NumberInput';
import SelectInput from 'components/inputs/SelectInput';

export const renderInputElement = ({
  item,
  onChange,
  overrideElement,
  styleProps,
  showPrefix,
  isDisabled,
}) => {
  const Element = overrideElement || item.element;

  let attr = {
    isDisabled: isDisabled || item.isDisabled,
  };

  if (onChange) {
    if (Element === Input) {
      attr.onChange = (event) => {
        onChange(item.name, event.target.value);
      };
    } else {
      attr.onChange = (value) => {
        onChange(item.name, value);
      };
    }
  }

  return ({ field }) => {
    if (
      Element === TwDatePicker ||
      Element === TwNumberInput ||
      Element === CurrencyNumberInput ||
      Element === PercentageNumberInput
    ) {
      attr = {
        ...attr,
        inputProps: styleProps,
        showPrefix: showPrefix,
        placeholder: item.placeholder || '',
      };
    }

    if (Element === SelectInput) {
      attr = {
        ...attr,
        value: field.value,
        isNumber: item.isNumber,
        isMulti: item.isMulti,
        options: item.options || [],
        placeholder: item.placeholder || '',
      };
    }

    return <Element {...field} {...attr} {...styleProps} {...item.props} />;
  };
};