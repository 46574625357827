import Decimal from 'decimal.js';
import moment from 'moment';

export const parseCohortsCsv = (csvData) => {
  return new Promise((resolve, reject) => {
    let result = [];

    for (let i = 1; i < csvData.data.length; i++) {
      const row = csvData.data[i];

      if (row.every((cell) => cell === '')) {
        continue;
      }

      const mDate = moment(row[0], 'MMM-YY');
      const users = Number(row[1].replace(/,/g, ''));
      const totalUA = Number(row[2].replace(/,/g, '').replace('$', ''));

      if (!mDate.isValid()) {
        reject(`Invalid date on row ${i}`);
        return;
      }

      if (isNaN(users)) {
        reject(`Invalid value for users on row ${i}`);
        return;
      }

      if (isNaN(totalUA)) {
        reject(`Invalid value for total UA on row ${i}`);
        return;
      }

      const roasValues = row.slice(3).map((value, j) => {
        const roas = Number(value.replace(/,/g, '').replace('%', ''));
        if (isNaN(roas)) {
          reject(`Invalid value for ROAS on row ${i}, column ${j + 3}`);
          return;
        }

        return Decimal.div(roas, 100).toNumber();
      });

      result.push({
        date: mDate.format('YYYY-MM-DD'),
        users,
        ua: totalUA,
        values: roasValues,
      });
    }

    resolve(result);
  });
};

export const parseAccountingCsv = (csvData) => {
  const mapParams = {
    revenue: 'revenue',
    cogs: 'cogs',
    'direct marketing spend': 'directMarketingSpend',
    payroll: 'payroll',
    overhead: 'overhead',
    'non operating expenses': 'nonOperatingExpenses',
    'non operating income': 'nonOperatingIncome',
    'net income': 'netIncome',
    cash: 'cash',
    'accounts receivable': 'accountsReceivable',
    'other current assets': 'otherCurrentAssets',
    'other long term assets': 'otherLongTermAssets',
    'accounts payable': 'accountsPayable',
    'other short term debt': 'otherShortTermDebt',
    'other current liability': 'otherCurrentLiability',
    'long term debt': 'longTermDebt',
    'other long term liability': 'otherLongTermLiability',
    'common / pref equity / safe': 'commonPrefEquitySafe',
    'retained earnings / loss': 'retainedEarningsLoss',
  };

  return new Promise((resolve, reject) => {
    let result = [];

    const numberOfColumns = csvData.data[0].length - 1;

    for (let i = numberOfColumns; i > 0; i--) {
      let mDate = moment().clone().subtract(i, 'months');
      mDate.set('date', 1);
      result.push({
        date: mDate.format('YYYY-MM-DD'),
      });
    }

    for (let i = 1; i < csvData.data.length; i++) {
      const row = csvData.data[i];

      for (let j = 1; j < row.length; j++) {
        const value = row[j];

        if (value === '') {
          continue;
        }

        const paramName = row[0].toLowerCase().trim();
        if (!mapParams[paramName]) {
          reject(`Unknown parameter on row ${i} "${row[0]}"`);
          return;
        }

        const numValue = Number(value.replace(/,/g, ''));
        if (isNaN(numValue)) {
          reject(`Invalid value on row ${i}, column ${j}`);
          return;
        }

        result[j - 1][mapParams[row[0].toLowerCase()]] = numValue;
      }
    }

    resolve(result);
  });
};
