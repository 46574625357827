import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { DEAL_TYPE } from 'constants/dealConstants';
import { renderInputElement } from 'helpers/formHelper';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import InstallmentAmortizationSettings from './InstallmentAmortizationSettings';

const fieldsRow1 = [
  'repaymentFrequency',
  'repaymentDays',
  'daysBeforeEndOfMonth',
  'numberOfRepayments',
];

const fieldsRow2 = ['fee', 'firstRepaymentDate'];

const fieldsRow3 = ['cohortStartDate', 'cohortEndDate'];

const fieldsRow4 = ['revenueSharePct',  'revenueTotalType', 'pctFromRevenueShare'];

const FieldsRow = ({
  control,
  allFields,
  fieldNames,
  isDisabled,
  onFieldChange,
}) => {

  return (
    <Flex gap={4}>
      {fieldNames.map((name) => {
        const fieldItem = allFields.find((f) => f.name === name);

        if (fieldItem.isHidden) {
          return null;
        }

        return (
          <Box key={name} w={220}>
            <FormLabel fontSize={'sm'} whiteSpace={'nowrap'}>{fieldItem.label}</FormLabel>
            <Controller
              control={control}
              name={name}
              render={renderInputElement({
                item: fieldItem,
                styleProps: { fontWeight: 800 },
                showPrefix: true,
                isDisabled: isDisabled,
                onChange: (name, value) => {
                  onFieldChange(name, value);
                },
              })}
            />
          </Box>
        );
      })}
    </Flex>
  );
};

const RepaymentsScheduleSettings = ({
  amortization,
  amountSold,
  onBuildAmortization,
  onAmortizationChange,
  fields,
  onFieldChange,
  control,
  isPageDisabled,
  isAmortizationBuildDisabled,
  dealType,
  calcAmortizationRepaymentsSum
}) => {
  if (!fields || fields.length === 0) {
    return null;
  }

  const hasAmortization = amortization && amortization.length > 0;

  return (
    <Box>
      <Heading fontSize={16} mb={5}>
        Repayment Schedule
      </Heading>
      <VStack spacing={4} align={'left'}>
        <FieldsRow
          control={control}
          allFields={fields}
          fieldNames={fieldsRow1}
          isDisabled={isPageDisabled || hasAmortization}
          onFieldChange={onFieldChange}
        />
        <FieldsRow
          control={control}
          allFields={fields}
          fieldNames={fieldsRow2}
          isDisabled={isPageDisabled || hasAmortization}
          onFieldChange={onFieldChange}
        />
        {dealType === DEAL_TYPE.ROAS && (
          <>
            <FieldsRow
              control={control}
              allFields={fields}
              fieldNames={fieldsRow3}
              isDisabled={isPageDisabled || hasAmortization}
              onFieldChange={onFieldChange}
            />
            <FieldsRow
              control={control}
              allFields={fields}
              fieldNames={fieldsRow4}
              isDisabled={isPageDisabled || hasAmortization}
              onFieldChange={onFieldChange}
            />
          </>
        )}
        <Flex>
          <Button
            variant={'outline'}
            onClick={onBuildAmortization}
            mt={4}
            isDisabled={isAmortizationBuildDisabled || hasAmortization}
          >
            Build Schedule
          </Button>
        </Flex>
      </VStack>
      <Box mt={10}>
        <InstallmentAmortizationSettings
          repayments={amortization}
          amountSold={amountSold}
          onChange={onAmortizationChange}
          dealType={dealType}
          calcAmortizationRepaymentsSum={calcAmortizationRepaymentsSum}
        />
      </Box>
    </Box>
  );
};

RepaymentsScheduleSettings.propTypes = {
  amortizationRepayments: PropTypes.array,
  amountSold: PropTypes.number,
  fields: PropTypes.array,
  onFieldChange: PropTypes.func,
  control: PropTypes.object,
  isPageDisabled: PropTypes.bool,
  onAmortizationChange: PropTypes.func,
  onBuildAmortization: PropTypes.func,
  isAmortizationBuildDisabled: PropTypes.bool,
  dealType: PropTypes.string,
  calcAmortizationRepaymentsSum: PropTypes.func,
};

export default RepaymentsScheduleSettings;
