import { getCashflowModelDefaultsSettingsApi } from 'api/cashflowModelApi';
import CustomerSelect from 'components/cockpit/CustomerSelect';
import FileInput from 'components/inputs/FileInput';
import {
  CurrencyNumberInput,
  PercentageNumberInput,
  TwNumberInput,
} from 'components/NumberInput';
import { renderInputElement } from 'helpers/formHelper';
import { hasObjectAllValues, isObjectEmpty } from 'helpers/objectHelper';
import cloneDeep from 'lodash.clonedeep';
import Papa from 'papaparse';
import { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Link,
  SimpleGrid,
  Text,
  useToast,
} from '@chakra-ui/react';
import { updateCashflowModelDefaultsSettingsApi } from 'api/cashflowModelApi';
import { HelperTooltip } from 'components/Tooltip';
import { parseAccountingCsv, parseCohortsCsv } from './csvParsers';

const CashflowModelInputs = ({ onSubmit }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [parseCohortsError, setParseCohortsError] = useState(null);
  const [parseAccountingError, setParseAccountingError] = useState(null);
  const [cohortFile, setCohortFile] = useState(null);
  const [accountingFile, setAccountingFile] = useState(null);

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const formValues = watch();

  const fields = [
    {
      name: 'customerId',
      isCustomField: true,
      required:
        (formValues.roasCohorts && !formValues.accounting) ||
        (formValues.accounting && !formValues.roasCohorts),
      element: (
        <>
          <Text>Customer</Text>
          <Box w={200}>
            <CustomerSelect
              customerId={formValues.customerId}
              onChange={(customerId) => {
                setValue('customerId', customerId);
              }}
            />
          </Box>
        </>
      ),
    },
    {
      name: 'roasCohorts',
      isCustomField: true,
      required: !formValues.customerId,
      element: (
        <>
          <Text>ROAS Cohorts CSV</Text>
          <Flex gap={2} align={'center'}>
            <FileInput
              initialFile={cohortFile}
              allowedFileTypes={['text/csv']}
              onSelectFiles={(files) => {
                setCohortFile(files[0]);
                onSelectCohortsCsv(files[0]);
              }}
            />
            <Link
              href="csv/cashflow-model-cohorts-example.csv"
              fontSize={'xs'}
              variant={'underline'}
            >
              Example File
            </Link>
            {parseCohortsError && (
              <Text color={'red.500'}>{parseCohortsError}</Text>
            )}
          </Flex>
        </>
      ),
    },
    {
      label: 'Direct Marketing Spend',
      elements: [
        {
          name: 'uaSpend',
          element: CurrencyNumberInput,
          required: true,
        },
        {
          name: 'uaSpendNumberOfIterations',
          label: 'Number Of Iterations',
          placeholder: 'Number Of Iterations',
          element: TwNumberInput,
          required: true,
          validate: {
            largerThanNumberOfFunding: (value) => {
              return (
                value >= formValues.numberOfFunding ||
                'Should be equal or bigger than Number Of Funding'
              );
            },
          },
        },
        {
          name: 'uaSpendFactor',
          placeholder: 'Factor',
          label: 'Factor',
          element: TwNumberInput,
          props: { min: 0 },
          required: true,
          validate: {
            notZero: (value) => {
              return value !== 0 || `Factor can't be zero`;
            },
          },
        },
      ],
    },
    {
      name: 'accounting',
      isCustomField: true,
      element: (
        <>
          <Text>Accounting</Text>
          <Flex gap={2} align={'center'}>
            <FileInput
              initialFile={accountingFile}
              allowedFileTypes={['text/csv']}
              onSelectFiles={(files) => {
                setAccountingFile(files[0]);
                onSelectAccountingCsv(files[0]);
              }}
            />
            <Link
              href="csv/cashflow-model-accounting-example.csv"
              fontSize={'xs'}
              variant={'underline'}
            >
              Example File
            </Link>
            {parseAccountingError && (
              <Text color={'red.500'}>{parseAccountingError}</Text>
            )}
          </Flex>
        </>
      ),
    },
    {
      label: 'Cash Override',
      name: 'cashOverride',
      element: CurrencyNumberInput,
      tooltip:
        'This will override the cash value for the last month of actual data and will be used as the starting point for cash prediction calculations.',
    },
    {
      title: 'Funding Twelve',
    },
    {
      label: 'Funding from UA',
      name: 'twelveFundingFromUa',
      element: PercentageNumberInput,
      required: true,
    },
    {
      label: 'Number Of Funding',
      name: 'numberOfFunding',
      element: TwNumberInput,
      required: true,
    },
    {
      label: 'Number Of Repayments',
      name: 'numberOfRepayments',
      element: TwNumberInput,
      required: true,
    },
    {
      label: 'Fee',
      name: 'fee',
      element: PercentageNumberInput,
      required: true,
    },
    {
      label: 'Grace',
      name: 'grace',
      element: TwNumberInput,
      required: true,
    },
    {
      label: 'Revenue Share',
      name: 'revenueShare',
      element: PercentageNumberInput,
    },
  ];

  useEffect(() => {
    const values = fields.reduce((acc, field) => {
      if (field.name) {
        acc[field.name] = null;
      }
      return acc;
    }, {});
    reset(values);
  }, []);

  useEffect(() => {
    if (formValues.customerId) {
      loadCustomerDefaultValues(formValues.customerId);
    }
  }, [formValues.customerId]);

  const loadCustomerDefaultValues = async (customerId) => {
    try {
      setLoading(true);
      let defaultSettings = await getCashflowModelDefaultsSettingsApi(
        customerId
      );

      if (!defaultSettings) {
        defaultSettings = fields.reduce((acc, field) => {
          if (field.name && field.name !== 'customerId') {
            acc[field.name] = null;
          }
          return acc;
        }, {});
      }

      const newValues = { customerId, ...defaultSettings };
      reset(newValues);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to get default settings.',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const onSelectCohortsCsv = (file) => {
    const onParseComplete = async (csvData) => {
      let result = [];

      try {
        result = await parseCohortsCsv(csvData);
        setValue('roasCohorts', result);
      } catch (error) {
        setParseCohortsError(error);
      }
    };

    setParseCohortsError(null);
    setValue('roasCohorts', null);
    Papa.parse(file, { complete: onParseComplete });
  };

  const onSelectAccountingCsv = (file) => {
    const onParseComplete = async (csvData) => {
      let result = [];
      try {
        result = await parseAccountingCsv(csvData);
        setValue('accounting', result);
      } catch (error) {
        setParseAccountingError(error);
      }
    };

    setParseAccountingError(null);
    setValue('accounting', null);
    Papa.parse(file, { complete: onParseComplete });
  };

  const onUpdateDefaultSettings = async () => {
    if (!formValues.customerId) {
      return;
    }

    const customerId = formValues.customerId;
    const settings = {
      uaSpend: formValues.uaSpend,
      uaSpendNumberOfIterations: formValues.uaSpendNumberOfIterations,
      uaSpendFactor: formValues.uaSpendFactor,
      twelveFundingFromUa: formValues.twelveFundingFromUa,
      numberOfFunding: formValues.numberOfFunding,
      numberOfRepayments: formValues.numberOfRepayments,
      fee: formValues.fee,
      grace: formValues.grace,
      revenueShare: formValues.revenueShare,
    };

    try {
      setLoading(true);
      await updateCashflowModelDefaultsSettingsApi({ customerId, settings });
      toast({
        title: 'Success',
        description: 'Default settings updated.',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update default settings.',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  function isFormFilled() {
    const values = cloneDeep(formValues);

    const notRequiredFields = fields
      .filter((field) => !field.required)
      .map((field) => field.name);

    notRequiredFields.forEach((field) => {
      delete values[field];
    });

    return !isObjectEmpty(formValues) && hasObjectAllValues(values);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box w={'max'}>
        <SimpleGrid
          gridTemplateColumns={'min-content min-content'}
          whiteSpace={'nowrap'}
          justifyContent={'space-between'}
          spacingY={4}
          spacingX={8}
          alignItems={'center'}
          fontSize={'sm'}
        >
          {fields.map((item, index) => {
            if (item.isHidden) {
              return null;
            }

            if (item.isCustomField) {
              return <Fragment key={index}>{item.element}</Fragment>;
            }

            if (item.title) {
              return (
                <Fragment key={index}>
                  <Text key={index} fontWeight={800} fontSize={'15px'} mt={4}>
                    {item.title}
                  </Text>
                  <Box></Box>
                </Fragment>
              );
            }
            return (
              <Fragment key={index}>
                <>
                  <Flex align={'center'}>
                    <Text>{item.label}</Text>
                    {item.tooltip && <HelperTooltip content={item.tooltip} />}
                  </Flex>
                  {item.elements ? (
                    <Flex gap={4} align={'center'}>
                      {item.elements.map((subItem, index) => (
                        <Flex gap={2} align={'center'}>
                          {subItem.label && <Text>{subItem.label}</Text>}
                          <Box w={200} key={index}>
                            <FormControl isInvalid={errors[subItem.name]}>
                              <Controller
                                key={index}
                                control={control}
                                name={subItem.name}
                                render={renderInputElement({
                                  item: subItem,
                                  showPrefix: true,
                                  styleProps: { w: 200 },
                                })}
                                rules={
                                  subItem.validate && {
                                    validate: subItem.validate,
                                  }
                                }
                              />
                              <FormErrorMessage>
                                {errors[subItem.name]?.message}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                        </Flex>
                      ))}
                    </Flex>
                  ) : (
                    <Box w={200}>
                      <Controller
                        control={control}
                        name={item.name}
                        render={renderInputElement({
                          item,
                          showPrefix: true,
                          styleProps: { w: 200 },
                        })}
                      />
                    </Box>
                  )}
                </>
              </Fragment>
            );
          })}
        </SimpleGrid>
      </Box>
      <Flex justify={'end'} gap={4}>
        {formValues.customerId && (
          <Button
            variant={'outline'}
            onClick={onUpdateDefaultSettings}
            isDisabled={!isFormFilled()}
          >
            Save as Default
          </Button>
        )}
        <Button variant={'brand'} type={'submit'} isDisabled={!isFormFilled()}>
          Calculate
        </Button>
      </Flex>
    </form>
  );
};

export default CashflowModelInputs;
