import { createContext } from 'react';

import { useState } from 'react';

export const KpiCardExpandContext = createContext();

const KpiCardExpandProvider = ({ children }) => {
  const [state, setState] = useState({});

  const setExpandState = (id) => {
    if (!id) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const setGroupExpandState = (ids, value) => {
    if (!ids) {
      return;
    }

    setState((prevState) => {
      const newState = { ...prevState };

      ids.forEach((id) => {
        newState[id] = value;
      });

      return newState;
    });
  };

  return (
    <KpiCardExpandContext.Provider
      value={{
        state,
        setExpandState,
        setGroupExpandState,
      }}
    >
      {children}
    </KpiCardExpandContext.Provider>
  );
};

export default KpiCardExpandProvider;
