import { Box, Button, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { TwDatePicker } from 'components/DatePicker';
import { Currency } from 'components/Number';
import { CurrencyNumberInput } from 'components/NumberInput';
import { DEAL_TYPE } from 'constants/dealConstants';
import Decimal from 'decimal.js';
import { dateFormat } from 'helpers/propTypesHelper';
import { values as _values } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';

const Row = ({ children, gridTemplateColumns, bg }) => {
  return (
    <SimpleGrid
      gridTemplateColumns={gridTemplateColumns}
      spacingX={10}
      whiteSpace={'nowrap'}
      alignItems={'center'}
      my={1}
      py={1}
      pl={2}
      bg={bg}
      w={'max'}
    >
      {children}
    </SimpleGrid>
  );
};

const InstallmentAmortizationSettings = ({
  repayments,
  amountSold,
  onChange,
  dealType,
  calcAmortizationRepaymentsSum,
}) => {
  const [repaymentToAdd, setRepaymentToAdd] = useState({
    date: null,
    minAmount: null,
  });

  if (!repayments || repayments.length === 0) {
    return null;
  }

  const onFieldChange = (name, value, index) => {
    const newRepayments = [...repayments];
    newRepayments[index][name] = value;
    onChange(newRepayments);
  };

  const onAddRepayment = () => {
    const newRepayments = [...repayments, repaymentToAdd];
    onChange(newRepayments);
    setRepaymentToAdd({});
  };

  const onRemoveRepayment = (index) => {
    const newRepayments = [...repayments];
    newRepayments.splice(index, 1);
    onChange(newRepayments);
  };

  const excludeDates = repayments.map((repayment) =>
    moment(repayment.date).format('YYYY-MM-DD')
  );

  const gridTemplateColumns =
    dealType === DEAL_TYPE.ROAS
      ? '100px 150px 150px 150px 150px 100px 120px 120px 100px'
      : '100px 150px 150px 120px 120px 100px';

  const repaymentAmountSum = calcAmortizationRepaymentsSum(repayments);

  return (
    <Box overflow={'auto'}>
      <Row gridTemplateColumns={gridTemplateColumns}>
        <Box></Box>
        <Text fontWeight={800}>Repayment Date</Text>
        <Text fontWeight={800}>Min Amount</Text>
        {dealType === DEAL_TYPE.ROAS && (
          <>
            <Text fontWeight={800}>Start Revenue Activity</Text>
            <Text fontWeight={800}>End Revenue Activity</Text>
            <Text fontWeight={800}>Revenue Share</Text>
          </>
        )}
        <Text fontWeight={800}>Expected Repayment</Text>
        <Text fontWeight={800}>Actual Repayment</Text>
        <Box></Box>
      </Row>
      <>
        {repayments.map((repayment, index) => {
          const isRepaymentDue = moment(repayment.date).isBefore(moment());
          const rowBg = isRepaymentDue ? 'horizonRed.100' : '';
          const isDisabled = !!repayment.actualPaidAmount;

          return (
            <Row
              key={`repayment${index}`}
              bg={rowBg}
              gridTemplateColumns={gridTemplateColumns}
            >
              <Text>{`Repayment ${index + 1}`}</Text>
              <TwDatePicker
                value={repayment.date}
                dateFormat={'MM/dd/yyyy'}
                hideCloseButton
                onChange={(value) => onFieldChange('date', value, index)}
                excludeDates={excludeDates}
                isDisabled={isDisabled}
              />
              <CurrencyNumberInput
                value={repayment.minAmount}
                onChange={(value) => onFieldChange('minAmount', value, index)}
                isDisabled={isDisabled}
              />
              {dealType === DEAL_TYPE.ROAS && (
                <>
                  <TwDatePicker
                    value={repayment.revenueStartDate}
                    dateFormat={'MM/dd/yyyy'}
                    hideCloseButton
                    onChange={(value) =>
                      onFieldChange('revenueStartDate', value, index)
                    }
                    isDisabled={isDisabled}
                  />
                  <TwDatePicker
                    value={repayment.revenueEndDate}
                    dateFormat={'MM/dd/yyyy'}
                    hideCloseButton
                    onChange={(value) =>
                      onFieldChange('revenueEndDate', value, index)
                    }
                    isDisabled={isDisabled}
                  />
                  {repayment.revenueShareAmount === null ? (
                    <Text>N/A</Text>
                  ) : (
                    <Currency
                      value={repayment.revenueShareAmount}
                      maximumFractionDigits={2}
                    />
                  )}
                </>
              )}
              {repayment.amountToPay === null ? (
                <Box></Box>
              ) : (
                <Box>
                  <Currency
                    value={repayment.amountToPay}
                    maximumFractionDigits={2}
                  />
                </Box>
              )}
              {repayment.actualPaidAmount === null ? (
                <Box></Box>
              ) : (
                <Box>
                  <Currency
                    value={repayment.actualPaidAmount}
                    maximumFractionDigits={2}
                  />
                </Box>
              )}
              <Box textAlign={'left'}>
                {isDisabled ? (
                  <Box></Box>
                ) : (
                  <Button
                    variant={'link'}
                    color={'red.500'}
                    onClick={() => onRemoveRepayment(index)}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            </Row>
          );
        })}
      </>
      <Row gridTemplateColumns={gridTemplateColumns}>
        <Box></Box>
        <Box></Box>
        <Flex position={'relative'} ml={4} fontWeight={800} fontSize={'15px'}>
          <Text position={'absolute'} left={'-100px'}>
            Remaining:{' '}
          </Text>
          <Currency
            value={new Decimal(amountSold).minus(repaymentAmountSum).toNumber()}
            maximumFractionDigits={2}
          />
        </Flex>
        {dealType === DEAL_TYPE.ROAS && (
          <>
            <Box></Box>
            <Box></Box>
          </>
        )}
        <Box></Box>
      </Row>
      <Row gridTemplateColumns={gridTemplateColumns}>
        <Box></Box>
        <Box></Box>
        <Flex
          position={'relative'}
          ml={4}
          mb={4}
          fontWeight={800}
          fontSize={'15px'}
        >
          <Text position={'absolute'} left={'-100px'}>
            Amount Sold:{' '}
          </Text>
          <Currency value={amountSold} maximumFractionDigits={2} />
        </Flex>
        {dealType === DEAL_TYPE.ROAS && (
          <>
            <Box></Box>
            <Box></Box>
          </>
        )}
        <Box></Box>
        <Box></Box>
        <Box></Box>
      </Row>
      <Row gridTemplateColumns={gridTemplateColumns}>
        <Text fontWeight={800}>Add Repayment</Text>
        <TwDatePicker
          value={repaymentToAdd.date}
          dateFormat={'MM/dd/yyyy'}
          hideCloseButton
          onChange={(value) => {
            setRepaymentToAdd((repayment) => ({ ...repayment, date: value }));
          }}
          excludeDates={excludeDates}
        />
        <CurrencyNumberInput
          value={repaymentToAdd.minAmount}
          onChange={(value) =>
            setRepaymentToAdd((repayment) => ({
              ...repayment,
              minAmount: value,
            }))
          }
        />
        {dealType === DEAL_TYPE.ROAS && (
          <>
            <TwDatePicker
              value={repaymentToAdd.revenueStartDate}
              dateFormat={'MM/dd/yyyy'}
              hideCloseButton
              onChange={(value) =>
                setRepaymentToAdd((repayment) => ({
                  ...repayment,
                  revenueStartDate: value,
                }))
              }
            />
            <TwDatePicker
              value={repaymentToAdd.revenueEndDate}
              dateFormat={'MM/dd/yyyy'}
              hideCloseButton
              onChange={(value) =>
                setRepaymentToAdd((repayment) => ({
                  ...repayment,
                  revenueEndDate: value,
                }))
              }
            />
          </>
        )}
        <Button
          variant={'outline'}
          onClick={onAddRepayment}
          isDisabled={_values(repaymentToAdd).some((v) => !v)}
        >
          Add
        </Button>
      </Row>
    </Box>
  );
};

InstallmentAmortizationSettings.propTypes = {
  repayments: PropTypes.arrayOf(
    PropTypes.shape({
      date: dateFormat,
      minAmount: PropTypes.number,
    })
  ),
  amountSold: PropTypes.number,
  onChange: PropTypes.func,
  dealType: PropTypes.string,
  calcAmortizationRepaymentsSum: PropTypes.func,
};

export default InstallmentAmortizationSettings;
