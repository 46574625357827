import { WarningIcon } from '@chakra-ui/icons';
import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  StackDivider,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { getPlaidAccountsApi, getPlaidIdentityApi } from 'api/connectorsApi';
import { Currency } from 'components/Number';
import { sumBy as _sumBy } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const BankAccountDetailsModal = ({
  customerId,
  itemId,
  bankIcon,
  bankName,
  isConnectionBroken,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [minContentHeight, setMinContentHeight] = useState(300);
  const toast = useToast();

  useEffect(() => {
    if (isOpen) {
      setMinContentHeight(360);
      loadData();
    }
  }, [isOpen]);

  useEffect(() => {
    if (data) {
      setMinContentHeight('auto');
    }
  }, [data]);

  const loadData = async () => {
    if (isConnectionBroken) {
      return;
    }

    setLoading(true);
    try {
      const promises = [getPlaidAccounts(), getPlaidIdentity()];
      const [accounts, identity] = await Promise.all(promises);

      const { identityAccounts, isSupported } = identity;
      if (isSupported) {
        accounts.forEach((account) => {
          const { owners } = identityAccounts.find(
            (identityAccount) => identityAccount.account.id === account.id
          );
          account.owners = owners;
        });
      }

      setData({
        accounts,
      });
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getPlaidIdentity = async () => {
    try {
      const _data = await getPlaidIdentityApi(customerId, itemId);
      return _data;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Error loading Plaid identity',
        status: 'error',
      });
      onClose();
      throw error;
    }
  };

  const getPlaidAccounts = async () => {
    try {
      const _data = await getPlaidAccountsApi(customerId, itemId);
      return _data;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Error loading Plaid accounts',
        status: 'error',
      });
      onClose();
      throw error;
    }
  };

  const Account = ({ account }) => {
    const { mask, officialName, type, subtype, id } = account;
    return (
      <Box>
        <Flex gap={1} align={'center'}>
          <Box fontWeight={'bold'}>
            {officialName ? `${officialName} - ${mask}` : mask}
          </Box>
          <Box color={'gray.main'} fontSize={'sm'}>
            ({subtype ? `${type} / ${subtype}` : type})
          </Box>
        </Flex>
        <Box fontSize={'sm'}>
          <Flex gap={1}>
            <Text fontFamily={800}>Available Balance:</Text>{' '}
            <Currency
              value={account.balances.available}
              maximumFractionDigits={2}
            />
          </Flex>
          <Flex gap={1}>
            <Text fontFamily={800}>Current Balance:</Text>{' '}
            <Currency
              value={account.balances.current}
              maximumFractionDigits={2}
            />
          </Flex>
        </Box>
        {account.owners &&
          account.owners.map((owner, index) => (
            <Box mt={4} key={`${account.id}_owner${index}`}>
              <AccountOwners key={`owner${id}${index}`} owner={owner} />
            </Box>
          ))}
      </Box>
    );
  };

  const AccountOwners = ({ owner }) => {
    const { names, emails, phoneNumbers, addresses } = owner;
    return (
      <Box fontSize={'sm'}>
        <Flex gap={10}>
          <Box>
            <Box fontWeight={'600'}>Names</Box>
            {names?.map((name, index) => (
              <Text key={`name${index}`}>{name}</Text>
            ))}
          </Box>
          <Box>
            <Box fontWeight={'600'}>Emails</Box>
            {emails?.map((email, index) => (
              <Text key={`email${index}`}>{email}</Text>
            ))}
          </Box>
          {phoneNumbers && (
            <Box>
              <Box fontWeight={'600'}>Phone Numbers</Box>
              {phoneNumbers?.map((phoneNumber, index) => (
                <Text key={`phoneNumber${index}`}>{phoneNumber}</Text>
              ))}
            </Box>
          )}
          {addresses?.length > 0 && (
            <Box>
              <Box fontWeight={'600'}>Addresses</Box>
              {addresses?.map((address, index) => (
                <Text
                  key={`address${index}`}
                >{`${address.street}, ${address.city}, ${address.region} ${address.postalCode}`}</Text>
              ))}
            </Box>
          )}
        </Flex>
      </Box>
    );
  };

  const renderBody = () => {
    if (loading) {
      return (
        <AbsoluteCenter>
          <Spinner />
        </AbsoluteCenter>
      );
    }

    if (isConnectionBroken) {
      return (
        <Center padding={'120px'}>
          <WarningIcon color={'gray.main'} w={10} h={10} />
          <Text fontSize={'xl'} color={'gray.main'} ml={2}>
            The connection to this bank is broken. Cannot retreive data.
          </Text>
        </Center>
      );
    }

    if (!data) {
      return null;
    }

    const { accounts } = data;

    return (
      <VStack
        divider={<StackDivider borderColor={'gray.main'} />}
        align="stretch"
        spacing={8}
        p={2}
        justifyContent={'flex-start'}
      >
        {accounts.map((account) => (
          <Account key={account.id} account={account} />
        ))}
        <Box fontSize={'md'}>
          <Flex gap={1}>
            <Text color={'gray.main'}>Total Available Balance:</Text>
            <Currency
              value={_sumBy(accounts, (a) => {
                return a.type === 'credit'
                  ? a.balances.available * -1
                  : a.balances.available;
              })}
              maximumFractionDigits={2}
            />
          </Flex>
          <Flex gap={1}>
            <Text color={'gray.main'}>Total Current Balance:</Text>
            <Currency
              value={_sumBy(accounts, (a) => {
                return a.type === 'credit'
                  ? a.balances.current * -1
                  : a.balances.current;
              })}
              maximumFractionDigits={2}
            />
          </Flex>
        </Box>
      </VStack>
    );
  };

  return (
    <>
      <Button
        color={'primary.main'}
        variant={'link'}
        fontSize={'xs'}
        onClick={onOpen}
      >
        (Details)
      </Button>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          w={'auto'}
          maxW={'auto'}
          minW={800}
          minH={minContentHeight}
        >
          <ModalHeader>
            <Flex gap={2} align={'center'}>
              {bankIcon} <Box>{bankName} - Owners Info</Box>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            maxH={'calc(100vh - 300px)'}
            overflowY={'auto'}
            position={'relative'}
          >
            {renderBody()}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

BankAccountDetailsModal.propTypes = {
  customerId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  bankIcon: PropTypes.element,
  bankName: PropTypes.string,
  isConnectionBroken: PropTypes.bool,
};

export default BankAccountDetailsModal;
