import { calcCashflowModelApi } from 'api/cashflowModelApi';
import Card from 'components/card/Card';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import { useState } from 'react';
import DetailsCsv from './DetailsCsv';
import ResultsTable from './ResultsTable';

import { Box, Divider, Flex, useToast } from '@chakra-ui/react';
import CashflowModelInputs from './CashflowModelInputs';
import ResultsKpis from './ResultsKpis';

const CashflowModel = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const [results, setResults] = useState(null);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const _results = await calcCashflowModelApi(data);
      setResults(_results);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to calculate model.',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box minW={'container.lg'} maxW={'max'}>
      <OverlaySpinnerWrapper show={loading} isFixed={true}>
        <Card mt={10}>
          <CashflowModelInputs onSubmit={onSubmit} />

          {results && (
            <Box mt={10}>
              <Divider borderColor={'secondaryGray.900'} />
              <Box mt={10}>
                <Flex>
                  <DetailsCsv
                    cohortsRawData={results.cohorts}
                    roasVector={results.result_items.roas_100_avg_6}
                  />
                </Flex>
                <Box mt={5}>
                  <ResultsKpis results={results.result_items} />
                </Box>
                <Box mt={10}>
                  <ResultsTable results={results.result_items} />
                </Box>
              </Box>
            </Box>
          )}
        </Card>
      </OverlaySpinnerWrapper>
    </Box>
  );
};

export default CashflowModel;
